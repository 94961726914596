import ProductCard from '@/components/cards/ProductCard';

import { useRouter } from 'next/router';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import useScreenSize from '@/hooks/display/useDisplay';

const ProductSwiper = ({
  set_swiper,
  products,
  ratings,
  categoryID,
  setCart = null,
  categoryName,
}) => {
  const { locale } = useRouter();

  const { screenWidth } = useScreenSize();
  const isMobile = screenWidth < 500;
  return (
    <Swiper
      onInit={(e) => (set_swiper ? set_swiper(e) : null)}
      spaceBetween={isMobile ? 10 : 16}
      key={locale}
      dir={locale === 'en' ? 'ltr' : 'rtl'}
      slidesPerView={isMobile ? 2.5 : 6}
      // breakpointsBase='container'
      // breakpoints={
      //   breakPoints || {
      //     320: { slidesPerView: 2.5 },
      //     465: { slidesPerView: 2.5 },
      //     641: { slidesPerView: 2.5, spaceBetween: 15 },
      //     769: { slidesPerView: 2.5 },
      //     1024: { slidesPerView: 4 },
      //     1440: { slidesPerView: 5 },
      //     // 1501: { slidesPerView: 6 },
      //   }
      // }
      loop={(products?.length > 6 && screenWidth > 1200) || screenWidth < 1200}
      modules={[Autoplay]}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      }}
      className={`h-full ${!isMobile ? 'w-[1440px]' : 'w-[' + screenWidth + 'px]'}`}
    >
      {products?.map((product, i) => (
        <SwiperSlide key={`product-swiper-2-${i}`}>
          <ProductCard
            product={product}
            categoryID={categoryID}
            setCart={setCart}
            categoryName={categoryName}
            ratings={ratings}
            isPriority={isMobile ? i <= 2 : i <= 5}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default ProductSwiper;
